@import '../../../../defaults';

.anw-reset-btn {
  &:focus,
  &:active {
    box-shadow: none !important;
  }
}
.anw-filter-wrapper {
  max-height: 342px;
  overflow-y: auto;
}

.resultFilter {
  p {
    color: $body-color;
    display: inline-block;
    font-size: $font-size-base;
    font-weight: 600;
    margin-bottom: 14px;
    padding-left: 0;
    padding-top: 0;
    text-transform: none;

    &.onlyHeadlineVisible {
      margin: 0;
    }
  }
}

.filterControls a {
  position: absolute;
  height: 40px;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  font-size: 20px;
}

.anw-filter.closed .filterControls a {
  height: 100%;
}

.filterControls a i {
  float: right;
}

.filterControls .arrowUp {
  position: relative;
  top: -3px;
}

.filterControls .arrowDown {
  position: relative;
  top: -3px;
}

.listFilterContainer {
  max-height: 342px;
  overflow-y: auto;
}

.anw-list-filter li {
  float: left;
  margin-bottom: 11px;
  width: 100%;
}

.anw-active-filter a {
  display: inline;
}

.anw-active-filter p {
  color: $body-color;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
  padding-left: 0;
  padding-top: 0;
  text-transform: none;
}

.anw-active-filter .anw-remove-all-filters {
  float: left;
  margin-top: 4px;
  padding-top: 8px;
  width: 100%;
}

.anw-list-filter a,
.anw-list-filter a:hover {
  display: block;
  text-decoration: none;
}

.anw-list-filter .anw-filter-text {
  float: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 75%;
}

.anw-active-filter .anw-filter-text {
  float: left;
  width: 80%;
}

.anw-list-filter .filterCount {
  float: right;
}

i.anw-filter-icon {
  position: relative;
  top: 0.2em;
  left: 0;
  float: left;
  display: inline-block;
  margin-right: 10px;
  color: #900;
  font-size: 18px;
}

.anw-filter-text {
  margin-left: 0;
  display: inline-block;
}

.anw-active-filter li {
  float: left;
  width: 100%;
  margin-bottom: 9px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2px;
  }
}
